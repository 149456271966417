export default [
    {
        path: '',
        name: 'home',
        meta: {
            title: 'main',
        },
        component: () => import(/* webpackChunkName: "home-view" */ './views/HomeScreen.vue'),
    },
    {
        path: '/schedule',
        name: 'schedule',
        meta: {
            title: 'Schedule',
        },
        component: () => import(/* webpackChunkName: "schedule-view" */ './views/ScheduleScreen.vue'),
    },
    {
        path: '/events',
        name: 'events',
        meta: {
            title: 'events',
        },
        component: () => import(/* webpackChunkName: "schedule-view" */ './views/ScheduleScreen.vue'),
    },
]
